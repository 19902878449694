.socials-container {
    display: block;
    width: 95%;
    height: 30px;
    margin: auto;
    position: relative;
    box-sizing: border-box;
}
// ul {
//     display: flex;
//     overflow: auto;
//     scrollbar-width: none;
//     // justify-content: flex-end;
//     padding-inline-start: 0px;
//     width: 100%;
//     margin-block-start: 0px;
//     margin-block-end: 0px;
//     margin-inline-start: 0px;
//     margin-inline-end: 0px;
// }
// ul::-webkit-scrollbar { 
//     display: none;  /* Safari and Chrome */
// }
// ul li {
//     list-style: none;
//     // width: 30px;
// }

// ul li a {
//     height: 65%;
//     min-height: 20px;
//     background-color: #fff;
//     display: block;
//     // border-radius: 50%;
//     position: relative;
//     overflow: hidden;
//     border: 1px solid #fff;
// }

// ul li div {
//     cursor: pointer;
// }

.menu-anchor {
    height: 20px;
    float: left;
    background-color: #fff;
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

// ul li a .icon {
//     position: relative;
//     padding: 3px 4px 3px 3px;
//     padding-top: 4px;
//     color: #fff;
//     transition: .5s;
//     z-index: 1;
//     height: 17px;
// }

div .menu {
    position: relative;
    color: #0c0c0b;
    transition: .5s;
    z-index: 1;
    height: 18px;
}

// ul li a:hover .icon {
//     color: #fff;
//     transform: rotateY(360deg);
// }

// ul li div:hover .menu {
//     color: #fff;
//     transform: rotateY(180deg);
// }

.menu-animate {
    animation-name: wobble;
    animation-iteration-count: 1;
    animation-duration: 1.1s;
    // animation-delay: 0.5s;
}

.menu-animate-back {
    animation-name: wobbleBack;
    animation-iteration-count: 1;
    animation-duration: 1.1s;
}

@keyframes wobble {
    25% {
      transform: rotate(10deg);
    }
    50% {
      transform: rotate(-10deg);
    }
    75% {
      transform: rotate(10deg);
    }
    100% {
      transform: rotate(0deg);
    }
}

@keyframes wobbleBack {
    25% {
      transform: rotate(10deg);
    }
    50% {
      transform: rotate(-10deg);
    }
    75% {
      transform: rotate(10deg);
    }
    100% {
      transform: rotate(0deg);
    }
}
 

a:hover .icon path{
    fill: #fff
}

div .menu {
    fill: #0c0c0b; 
}

a .twitter {
    fill:  #55acee;
}

a .linkedin {
    fill: #0077b5;
}

a .twitch {
    fill: #6441a5;
}

a:hover .twitter{
    padding-top: 6px !important;
    height: 15px !important;
}

a:hover .linkedin{
    padding-top: 6px !important;
    height: 14px !important;
}

a:hover .twitch {
    padding-top: 6px !important;
    height: 15px !important;
}

// ul li a:before {
//     content: "";
//     position: absolute;
//     top: 100%;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: #f00;
//     transition: .5s;
//     z-index: 1;
// }


// ul li a:hover:before {
//     top: 0;
// }

// ul li div:hover:before {
//     top: 0;
// }

// ul li:nth-child(1) div:before{
//     background: #fff;
// }

// ul li:nth-child(2) a:before{
//     background: #55acee;
// }

// ul li:nth-child(3) a:before {
//     background: #0077b5;
// }

// ul li:nth-child(4) a:before {
//     background: #0c0c0b;
// }

// ul li:nth-child(5) a:before{
//     background: #6441a5;
// }

@media screen and (max-width: 480px) {
    .social-bar-container {
        display: flex;
        .socials-container {
            flex: 1;
        }
        .social-mobile-home {
            flex: 1;
        }
        // ul {
        //     justify-content: flex-end;
        //     margin-block-start: 0px;
        //     margin-block-end: 0px;
        // }
    
        // ul li a {
        //     height: 60%;
        // }
    
        div .menu {
            height: 25px;
        }
    
        // ul li a .icon {
        //     height: 22px;
        // }
    
        a:hover .twitter{
            padding-top: 6px !important;
            height: 18px !important;
        }
        
        a:hover .linkedin{
            padding-top: 6px !important;
            height: 18px !important;
        }
        
        a:hover .twitch {
            padding-top: 6px !important;
            height: 18px !important;
        }
    
        .menu-anchor {
            height: 20px;
            float: right;
            background: transparent;
            padding-right: 5px;
            padding-left: 5px;
        }
    }
}