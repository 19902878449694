.home-container {
    height: 35px;
    position: sticky;
    width: 100%;
    position: -webkit-sticky;
    z-index: 1;
    display: block;
    top: 0;
    background: white;
    .fade-enter {
        opacity: 0;
    }
      
    .fade-enter-active {
        opacity: 1;
        // transition: opacity 1000ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
        transform: translateX(0px);
        transition: opacity 800ms, transform 400ms;
    }
      
    .fade-exit {
        opacity: 1;
    }
      
    .fade-exit-active {
        opacity: 1;
        // transition: opacity 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transform: translateX(0px);
        transition: opacity 800ms, transform 400ms;
    }
    .home-logo {
        height: 25px;
        padding-left: 0px;
        padding-top: 5px;
        cursor: pointer;
    }
}

@media screen and (max-width: 480px) {
    .home-container {
        height: 0px;
        .home-logo {
            display: none;
        }
    }
}