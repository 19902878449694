.blog-page {
    background-color: white;
    margin: auto;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    font-family: 'Baloo Tammudu 2', cursive;
    .slide-enter {
        opacity: 0;
        transform: translateY(20%);
    }
    .slide-enter-active {
        opacity: 1;
        transform: translateX(0px);
        transition: opacity 800ms, transform 400ms;
    }
    .slide-exit {
        opacity: 1;
    }
    .slide-exit-active {
        opacity: 0;
        transform: translateX(-100%);
        transition: opacity 300ms, transform 300ms;
    }
    .highlight {
        float: left;
        width: 10%;
        height: 100%;
        padding: 5px;
        border-radius: 25px;
        overflow: hidden;
        box-sizing: border-box;
        position: relative;
        .footer {
            font-family: 'Baloo Tammudu 2', cursive;
            font-size: 14px;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: auto;
            text-align: center;
            padding-bottom: 10px;
            padding-right: 5px;
            box-sizing: border-box;
            background-color: white;
        }
    }
    .blog-body {
        width: 90%;
        padding-left: 0px;
        padding-right: 30px;
        float: right;
        margin-top: 10px;
        height: calc(100% - 10px);
        overflow: auto;
        background-color: white;
        box-sizing: border-box;
        font-family: 'Baloo Tammudu 2', cursive;
        code {
            font-size: 16px;
        }
        .code-editor {
            background-color: rgba(49, 52, 64, 1);
            border-radius: 5px;
            box-shadow: 0 10px 6px -6px #000;
            border: 1px solid #1B1C22;
            font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier;
            font-weight: bold;
            margin-top: 15px;
            > .tabs {
              background-color: transparent;
            }
          
            > .tabs > .title {
              width: 60%;
              position: relative;
              padding: 5px 0 7px 5px;
              text-align: center;
              font-size: 18px;
              word-wrap: break-word;
            
              &.-red { color: #D16262; }
              &.-oat { color: #DBB984; }
              &.-orange { color: #E27B60; }
              &.-blue { color: #75E0FD; }
          
              &:after {
                position: absolute;
                top: 0;
                left: 100%;
                content: '';
                color: #6E7180;
                width: 65%;
                padding-top: 5px;
                height: 100%;
                background-color: rgba(38, 40, 49, 1);
                border-bottom: 1px solid #1B1C22;
                border-left: 1px solid #1B1C22;
              }
            }
          
            >.tabs > .title > .icon {
              position: relative;
              top: 2px;
              margin-right: 5px;
              color: inherit;
              font-size: 18px;
            }
          
            > .content {
              padding: 20px 0 20px 10px;
              font-size: 16px;
            }
          
            > .content > .lines {
              list-style: none;
              padding: 0;
              margin: 0;
            }
          
            .lines > .line ::selection {
              background-color: rgba(62, 68, 81, 0.8);
            }
          
            .lines > .line {
              white-space: nowrap;
            }
            
            .lines > .line > span {
              white-space: normal;
              word-wrap: break-word;
            }
          
            .lines .linenumber {
              color: #414556;
              padding: 0 15px 0 0;
              font-weight: normal;
            }
          
            .red        { color: #e06c75; }
            .purple     { color: #c678dd; }
            .yellow     { color: #FECF5F; }
            .orange     { color: #FB8578; }
            .blue       { color: #7EBAFF; }
            .green      { color: #98c379; }
            .light-blue { color: #75E0FD }
            .comment    { color: #5c6370; font-style: italic; }
            .white      { color: white }
        }
        .blog-p {
            display: block;
            width: 95%;
            height: auto;
            position: relative;
            margin: auto;
            box-sizing: border-box;
            padding: 0px 60px 10px 60px;
            font-size: 20px;
            .blog-list-container {
                .blog-list-header {
                    display: block;
                    text-decoration: underline;
                    font-size: 24px;
                    text-align: center;
                }
                .blog-list {
                    display: inline-block;
                    padding-inline-start: 20px;
                    margin-block-start: 0px;
                    margin-block-end: 0px;
                    ::marker {
                        font-size: 15px;
                    }
                    li {
                        list-style: circle;
                    }
                }
            }
        }
        .blog-image-container {
            display: block;
            position: relative;
            margin: auto;
            width: 100%;
            overflow: hidden;
            height: auto;
            padding: 10px 0px 10px 10px;
            box-sizing: border-box;
            .blog-image-l {
                position: relative;
                display: block;
                height: auto;
                margin: auto;
                width: 80%;
            }
            .pad-top {
                padding-top: 20px;
            }
            .pad-bot {
                padding-bottom: 20px;
            }
        }
        .blog-title {
            font-size: 32px;
            text-align: center;
        }
        .audio-container {
            width: 100%;
            position: relative; 
            display: block;
            audio {
                display: block;
                position: relative;
                margin: auto;
                padding: 10px;
            }
            .audio-footer {
                display: block;
                text-align: center;
                .footer-text {
                    padding: 0px 10px 0px 10px;
                }
                .bullet-sm {
                    font-weight: 700;
                }
            }
        }
        .blog-header-container {
            display: block;
            position: relative;
            margin: auto;
            width: 100%;
            overflow: hidden;
            height: auto;
            padding: 10px 0px 10px 10px;
            box-sizing: border-box;
            .blog-header {
                position: relative;
                display: block;
                height: auto;
                margin: auto;
                width: 95%;
            }
        }
        .blog-sub-header {
            font-size: 24px;
            text-align: center;
            padding-top: 10px;
        }
        .blog-center {
            text-align: center;
            padding: 10px;
        }
    }
    .mobile-header {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .blog-page {
        height: 100vh;  
        max-height: -webkit-fill-available;
        overflow: hidden;
        padding: 10px 10px 0px 10px;
        .highlight {
            display: none;
        }
        .blog-body {
            width: 100%;
            padding: 10px;
            margin: auto;
            float: none;
            height: calc(100% - 100px);
            box-sizing: border-box;
            -webkit-overflow-scrolling: touch;
            overscroll-behavior: none;
            .blog-header-container {
                padding: 0px;
                .blog-header {
                    width: 100%;
                }
            }
            .blog-title {
                padding-left: 0px;
                font-size: 20px;
                padding-top: 10px;
            }
            code {
                font-size: 14px;
            }
            .code-editor {
                > .tabs > .title {
                  width: 100%;
                  border-right: none;
                  font-size: 14px;
                  &:after {
                    display: none;
                  }
                }
                > .content {
                    font-size: 12px;
                }
              }
            .blog-p {
                padding: 0px;
                font-size: 16px;
                .blog-list-container {
                    .blog-list-header {
                        font-size: 18px;
                    }
                    .blog-list {
                        ::marker {
                            font-size: 12px;
                        }
                    }
                }
                .blog-list-header {
                    padding-left: 0px;
                    font-size: 18px;
                }
            }
            .blog-image-container {
                padding: 0px;
                .blog-image-l {
                    width: 100%;
                }
            }
            .blog-sub-header {
                padding-left: 0px;
                font-size: 18px;
            }
        }
        .mobile-header {
            display: block !important;
            text-align: right;
            width: 100%;
            position: sticky;
            position: -webkit-sticky;
            z-index: 1;
            .alert-mobile-enter {
                opacity: 0;
                // transform: scale(0.9);
                transform: translateY(-100%);
            }
            .alert-mobile-enter-active {
                opacity: 1;
                transform: translateX(0px);
                transition: opacity 300ms, transform 300ms;
            }
            .alert-mobile-exit {
                opacity: 1;
            }
            .alert-mobile-exit-active {
                opacity: 0;
                // transform: scale(0.9);
                transform: translateY(-100%);
                transition: opacity 300ms, transform 300ms;
            }
        }
    }
}