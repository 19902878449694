.twitterContainer {
    padding-top: 0px;
    overflow: hidden;
    height: 100%;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    .menu-container {
        display: block;
        width: 100%;
        font-family: 'Baloo Tammudu 2', cursive;
        font-size: 14px;
        padding-top: 10px;
        // padding-bottom: 20px;
        margin: auto;
        overflow: hidden;
        position: sticky;
        .menu-item:hover .about {
            text-decoration: underline;
            text-decoration-thickness: 1px;
        }
        .menu-item {
            display: inline-block;
            width: 100%;
            height: 30px;
            padding-left: 10px;
            border-bottom: 1px solid #d2d2d2;
            padding-bottom: 15px;
            padding-top: 10px;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
            &:last-child {
                border-bottom: none;
                // padding-bottom: 20px;
            }
            &:first-child {
                padding-top: 0px;
            }
            .info {
                height: 18px;
                width: 18px;
                animation-name: wiggleWiggle;
                animation-iteration-count: 1;
                animation-duration: .7s;
                cursor: pointer;
                display: inline-block;
                // &:hover {
                //     transform: rotateY(270deg);
                // }

            }
            .home {
                height: 15px;
                width: 15px;
                animation-name: wiggleWiggle;
                animation-iteration-count: 1;
                animation-duration: .7s;
                cursor: pointer;
                display: inline-block;
                padding-right: 3px;
            }
            .blog {
                height: 15px;
                width: 15px;
                animation-name: wiggleWiggle;
                animation-iteration-count: 1;
                animation-duration: .7s;
                cursor: pointer;
                display: inline-block;
                padding-right: 3px;
                // &:hover {
                //     transform: rotateY(270deg);
                // }
            }
            .books {
                height: 15px;
                width: 15px;
                animation-name: wiggleWiggle;
                animation-iteration-count: 1;
                animation-duration: .7s;
                cursor: pointer;
                display: inline-block;
                padding-right: 2px;
                // &:hover {
                //     transform: rotateY(270deg);
                // }
            }
            .about {
                display: inline-block;
                font-size: 20px;
                font-weight: 400;
                // &:hover {
                //     text-decoration: underline;
                //     text-decoration-thickness: 1px;
                // }
            }
        }
    }
    .close-menu {
        display: none;
    }
    .twitter-embed {
        // overflow: scroll;
        height: 100%;
        padding-bottom: 0px;
        #twitter-widget-0{
            min-width: auto !important;
        }
    }
}

@keyframes wiggleWiggle {
    0%, 10% { 
        transform: rotateY(0deg); 
    }
    25% {
        transform: rotateY(45deg);
    }
    50% {
        transform: rotateY(180deg);
    }
    90%, 100% {
        transform: rotateY(360deg);
    }
}

@keyframes pulse {
    0% {
      transform: scale(1.2);
    }
    
    25% {
      transform: scale(1.0);
    }

    50% {
        transform: scale(1.2);
    }
    
    75% {
        transform: scale(1.0);
    }

    100% {
      transform: scale(1.2);
    }
}

@media screen and (max-width: 480px) {
    .twitterContainer {
        display: block;
        position: fixed;
        background-color: rgba(255, 255, 255, 0.9);
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 100;
        .menu-container {
            text-align: center;
            padding-top: 25%;
            .menu-item {
                border-bottom: none;
                padding-left: 0px;
                // color: white;
                // background-color: rgba(255, 255, 255, 0.7);
                .about {
                    font-size: 30px;
                }
            }
        }
        .close-menu {
            display: block;
            float: left;
            height: 25px;
            width: 25px;
            padding-left: 20px;
            margin-top: 35px;
            // background-color: rgba(255, 255, 255, 0.7);
        }
        .twitter-embed {
            .swipe-up {
                height: 75px;
                display: block;
                position: absolute;
                bottom: 40px;
                width: 100%;
                .swipey {
                    height: 30px;
                    display: block;
                    position: absolute;
                    bottom: 40px;
                    left: 45%;
                    animation-name: pulse;
                    animation-iteration-count: 2;
                    animation-duration: 1.5s;
                }
            }
        }
    }
}