.preview-container {
    padding: 20px 20px 20px 0px;
    background: white;
    border-radius: 5px;
    min-height: 175px;
    font-family: 'Baloo Thambi 2', cursive;
    font-weight: 500;
    overflow: hidden;
    &:last-child {
        border-bottom: none;
    }
    .preview-container-left {
        position: relative;
        float: left;
        width: calc(60% - 20px);
        padding: 5px;
        .preview-title {
            font-size: 30px;
            padding-bottom: 10px;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
                text-decoration-thickness: 2px;
            }
        }
        .preview-subtitle {
            font-size: 18px;
            padding-bottom: 10px;
        }
        .preview-link {
            .link-text {
                display: inline-block;
                color: #0000EE;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                }
            }
        }
    }
    .preview-container-right {
        position: relative;
        padding: 5px;
        float: right;
        width: calc(40% - 20px);
        img {
            display: block;
            justify-content: center; 
            align-items: center;
            margin: auto;
            width: 100%;
            cursor: pointer;
            height: 250px;
            max-width: 475px;
        }
    }
}

.fade-enter {
    opacity: 0;
}
  
.fade-enter-active {
    opacity: 1;
    // transition: opacity 1000ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
    transform: translateX(0px);
    transition: opacity 800ms, transform 400ms;
}
  
.fade-exit {
    opacity: 1;
}
  
.fade-exit-active {
    opacity: 1;
    // transition: opacity 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform: translateX(0px);
    transition: opacity 800ms, transform 400ms;
}

@media screen and (max-width: 480px) {
    .preview-container {
        padding: 0px;
        overflow: hidden;
        box-sizing: border-box;
        .preview-container-top {
            display: block;
            position: relative;
            width: 100%;
            padding: 5px;
            img {
                display: flex;
                justify-content: center; 
                align-items: center;
                width: 100%;
            }
        }
        .preview-container-bot {
            display: block;
            position: relative;
            width: 95%;
            padding: 10px;
            .preview-title {
                font-size: 21px;
                padding-bottom: 10px;
            }
            .preview-body {
                font-size: 15px;
            }
            .preview-link {
                .link-text {
                    display: inline-block;
                    color: #0000EE;
                }
            }
        }
    }
}