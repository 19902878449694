.mobile-home-container {
    height: 30px;
    position: relative;
    width: 20%;
    // padding-top: 10px;
    z-index: 1;
    display: block;
    background: white;
    .fade-enter {
        opacity: 0;
    }
      
    .fade-enter-active {
        opacity: 1;
        // transition: opacity 1000ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
        transform: translateX(0px);
        transition: opacity 800ms, transform 400ms;
    }
      
    .fade-exit {
        opacity: 1;
    }
      
    .fade-exit-active {
        opacity: 1;
        // transition: opacity 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transform: translateX(0px);
        transition: opacity 800ms, transform 400ms;
    }
    .home-logo {
        height: 25px;
        padding-left: 0px;
        // padding-top: 5px;
        cursor: pointer;
        float: left;
    }
}
