.feed-container {
    width: 90%;
    padding-left: 0px;
    padding-right: 5px;
    float: right;
    margin-top: 10px;
    height: calc(100% - 10px);
    overflow: auto;
    box-sizing: border-box;
    // background: #efeff0;
}
.divider {
    position: relative;
    border-bottom: 1px solid #d2d2d2;
    width: 95%;
    display: block;
    margin: auto;
}

@media screen and (max-width: 480px) {
    .feed-container {
        width: 100%;
        float: none;
        margin: auto;
        position: relative;
        display: block;
        padding: 0px;
        height: calc(100% - 100px);
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior: none;
    }
}