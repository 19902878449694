.book-preview-container {
    padding: 0px 20px 20px 10px;
    background: white;
    border-radius: 5px;
    min-height: 175px;
    font-family: 'Baloo Thambi 2', cursive;
    font-weight: 500;
    overflow: hidden;
    box-sizing: border-box;
    width: 80%;
    margin: auto;
    &:last-child {
        border-bottom: none;
    }
    .book-preview-container-left {
        position: relative;
        padding: 20px 0px 20px 20px;
        float: left;
        width: 40%;
        box-sizing: border-box;
        img {
            display: block;
            justify-content: center;
            align-items: center;
            margin: auto;
            width: 45%;
        }
    }
    .book-preview-container-right {
        position: relative;
        float: right;
        width: 60%;
        box-sizing: border-box;
        padding: 20px 20px 20px 0px;
        .book-preview-title {
            font-size: 30px;
            padding-bottom: 10px;
        }
        .book-preview-subtitle {
            font-size: 18px;
            padding-bottom: 10px;
        }
    }
}

.fade-enter {
    opacity: 0;
}
  
.fade-enter-active {
    opacity: 1;
    // transition: opacity 1000ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
    transform: translateX(0px);
    transition: opacity 800ms, transform 400ms;
}
  
.fade-exit {
    opacity: 1;
}
  
.fade-exit-active {
    opacity: 1;
    // transition: opacity 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform: translateX(0px);
    transition: opacity 800ms, transform 400ms;
}

@media screen and (max-width: 480px) {
    .book-preview-container {
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;
        margin: auto;
        .book-preview-container-top {
            display: block;
            position: relative;
            width: 50%;
            margin: auto;
            padding: 10px;
            img {
                display: flex;
                justify-content: center; 
                align-items: center;
                width: 90%;
                margin: auto;
            }
        }
        .book-preview-container-bot {
            display: block;
            position: relative;
            width: 95%;
            padding: 10px;
            .book-preview-title {
                font-size: 21px;
                padding-bottom: 10px;
            }
            .book-preview-body {
                font-size: 15px;
            }
        }
    }
}