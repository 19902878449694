.blog-preview-container {
    padding: 20px 20px 20px 10px;
    background: white;
    border-radius: 5px;
    min-height: 175px;
    font-family: 'Baloo Thambi 2', cursive;
    font-weight: 500;
    overflow: hidden;
    width: 95%;
    margin: auto;
    .blog-preview-container-left {
        position: relative;
        float: left;
        width: calc(60% - 20px);
        padding: 10px;
        .blog-preview-title {
            font-size: 30px;
            padding-bottom: 10px;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
                text-decoration-thickness: 2px;
            }
        }
        .blog-preview-subtitle {
            font-size: 18px;
            padding-bottom: 10px;
        }
        .blog-preview-link {
            .blog-link-text {
                display: inline-block;
                cursor: pointer;
                color: #0000EE;
                &:hover {
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                }
            }
        }
    }
    .blog-preview-container-right {
        position: relative;
        padding: 5px;
        float: right;
        width: calc(40% - 20px);
        img {
            display: block;
            justify-content: center; 
            align-items: center;
            margin: auto;
            width: 100%;
            cursor: pointer;
        }
    }
}

.first {
    padding-top: 0px;
}

@media screen and (max-width: 480px) {
    .blog-preview-container {
        padding: 0px;
        overflow: hidden;
        box-sizing: border-box;
        .blog-preview-container-top {
            display: block;
            position: relative;
            width: 100%;
            padding: 5px;
            img {
                display: flex;
                justify-content: center; 
                align-items: center;
                width: 100%;
            }
        }
        .blog-preview-container-bot {
            display: block;
            position: relative;
            width: 95%;
            padding: 10px;
            .blog-preview-title {
                font-size: 21px;
                padding-bottom: 10px;
            }
            .blog-preview-body {
                font-size: 15px;
            }
            .blog-preview-link {
                .blog-link-text {
                    display: inline-block;
                    color: #0000EE;
                }
            }
        }
    }
}