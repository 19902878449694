.image-info-container {
    position: absolute !important;
    bottom: 0px !important;
    left: 5% !important;
    width: 20px !important;
    .image-info {
        width: 20px !important;
    }
    
    .tooltip {
        visibility: hidden !important;
        box-sizing: border-box;
        width: 400px !important;
        height: 175px !important;
        background-color: white;
        color: black;
        text-align: center;
        border-radius: 6px;
        padding: 20px;
        position: absolute;
        bottom: 20px;
        left: 25px;
        z-index: 1;
    }
    &:hover {
        .tooltip {
            visibility: visible !important;
        }
    }
}

.image-info-container-small {
    position: absolute !important;
    bottom: 0px !important;
    left: 20px !important;
    width: 20px !important;
    .image-info-small {
        width: 20px !important;
    }
    
    .tooltip-small {
        visibility: hidden !important;
        box-sizing: border-box;
        width: 250px !important;
        height: 80px !important;
        background-color: white;
        color: black;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        bottom: 20px;
        left: 25px;
        z-index: 1;
    }
    &:hover {
        .tooltip-small {
            visibility: visible !important;
        }
    }
}