.about-preview {
    margin: auto;
    width: 100%;
    box-sizing: border-box;
    font-family: 'Baloo Tammudu 2', cursive;
    text-align: center;
    .image-preview-enter {
        opacity: 0;
        transform: translateY(100%);
    }
    .image-preview-enter-active {
        opacity: 1;
        transform: translateX(0px);
        transition: opacity 800ms, transform 400ms;
    }
    
    .profile {
        border-radius: 50%;
        height: 300px;
        display: block;
        margin: auto;
        box-shadow: 10px 10px 5px #ccc;
        -moz-box-shadow: 10px 10px 5px #ccc;
        -webkit-box-shadow: 10px 10px 5px #ccc;
        -khtml-box-shadow: 10px 10px 5px #ccc;
    }
    .about-me {
        display: block;
        height: 100%;
        text-align: left;
        padding: 15px;
        font-size: 20px;
        padding-bottom: 30px;
        .header {
            font-size: 22px;
            font-weight: 500;
        }
    }

    .fade-enter {
        opacity: 0;
    }
      
    .fade-enter-active {
        opacity: 1;
        // transition: opacity 1000ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
        transform: translateX(0px);
        transition: opacity 800ms, transform 400ms;
    }
      
    .fade-exit {
        opacity: 1;
    }
      
    .fade-exit-active {
        opacity: 1;
        // transition: opacity 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transform: translateX(0px);
        transition: opacity 800ms, transform 400ms;
    }
      
}

@media screen and (max-width: 480px) {
    .about-preview {
        .profile {
            height: 250px;
        }
    }
}