.logo-container {
    display: block;
    font-family: 'Baloo Tammudu 2', cursive;
    box-sizing: border-box;
    font-size: 4vmin;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    margin: auto;
    height: auto;
    cursor: pointer;
    line-height: 1;
    padding-top: 20px;
    padding-left: 2px;
}

#period {
    display: inline-block;
}

#logo-letter {
    // -webkit-transition: -webkit-transform .65s;
    // -moz-transition: -moz-transform .65s;
    // transition: transform .65s;
    // animation-name: wiggleWiggle;
    animation-iteration-count: 0;
    animation-duration: .40s;
    cursor: pointer;
    display: inline-block;
    // transform: translateY(3px);
}

#logo-static {
    cursor: pointer;
    display: inline-block;
}

#logo-letter:hover {
    // -moz-transform: rotateY(180deg);
    // -webkit-transform: rotateY(-180deg);
    // transform: rotateY(180deg);
}

.d {
    // animation-delay: 0s;
}
.a {
    animation-delay: 0.04s;
}
.n {
    animation-delay: 0.08s;
}
.n_2 {
    animation-delay: 0.11s;
}
.y {
    animation-delay: 0.16s;
}
.c {
    animation-delay: 0.18s;
}

@keyframes wiggleWiggle {
    0%, 10% { 
        transform: rotateY(0deg); 
    }
    25% {
        transform: rotateY(45deg);
    }
    50% {
        transform: rotateY(180deg);
    }
    90%, 100% {
        transform: rotateY(360deg);
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
    .logo-container {
        height: 50px;
        font-size: 3vmax;
    }
}

@media screen and (max-width: 480px) {
    .logo-container {
        font-size: 45px;
        height: 65px;
        padding-top: 15px;
    }
}

  