body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background: #efeff0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.no-scrollbar {
  -ms-overflow-style: none !important; 
}

.no-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.page-body {
  overflow: auto;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #F0F0F0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
