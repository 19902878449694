.blog-header-container {
    .game-header {
        width: 80%;
        margin: auto;   
        display: block;
        position: relative;
    }
}
.cast-header {
    font-size: 24px;
    text-decoration: underline;
}

@media screen and (max-width: 480px) {
    .cast-header {
        font-size: 18px;
    }
}