.book-container {
    width: 90%;
    padding-left: 0px;
    padding-right: 5px;
    float: right;
    margin-top: 10px;
    height: calc(100% - 10px);
    overflow: auto;
    box-sizing: border-box;
    font-family: "Baloo Thambi 2", cursive;
    font-weight: 500;
    // background: #efeff0;
    .book-intro-container {
        display: block;
        width: 100%;
        float: left;
        padding-bottom: 15px;
        .book-intro-title {
            padding-bottom: 15px;
            font-size: 30px;
            text-align: center;
            text-decoration: underline;
            text-decoration-thickness: 2px;
        }
        .book-intro-body {
            width: 80%;
            margin: auto;
            font-size: 18px;
        }

    }
}
.book-divider {
    position: relative;
    border-bottom: 1px solid #d2d2d2;
    width: 70%;
    display: block;
    margin: auto;
}

@media screen and (max-width: 480px) {
    .book-container {
        width: 100%;
        float: none;
        margin: auto;
        position: relative;
        display: block;
        padding: 0px;
        height: calc(100% - 100px);
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior: none;
        .book-intro-container {
            .book-intro-body {
                width: 95%;
                margin: auto;
                font-size: 16px;
            }
            .book-intro-title {
                padding: 5px;
                font-size: 24px;
            }
    
        }
    }
}